import React from "react"
import styled from 'styled-components'
import { Video, Picture } from '@components'
import { Project } from '../../schema'



export default ({ image, video, studentLink, student, mediaTypeIsImage }: Project) => (
  <Wrapper>
    {mediaTypeIsImage && (
      <Picture src={image?.asset.url} />
    )}
    {!mediaTypeIsImage && (
      <Video src={video?.asset.url} autoPlay muted loop playsInline />
    )}
    {studentLink && (<Byline><a href={studentLink} target="_blank">{student}</a></Byline>)}
    {!studentLink && (<Byline>{student}</Byline>)}
  </Wrapper>
)


const Wrapper = styled.div`
  margin-bottom: 2em;
  @media only screen and (min-width: 744px) {
    grid-column-end: span 1;
  }
  img {
    margin-bottom: 0.25em;
  }
`


const Byline = styled.div`
  text-align: right;
  color: rgba(66, 181, 246, 1);
  font-family: Graphik, sans-serif;
  font-size: 12px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 1em;
`


