import React from "react"
import styled from 'styled-components'
import { graphql, useStaticQuery } from 'gatsby'
import { Helmet } from 'react-helmet'
import { Header, MouseTrail } from '@components'

import "../assets/stylesheets/reset.min.css"
import "../assets/stylesheets/main.scss"


interface LayoutProps {
  className?: string
  children: React.ReactNode
}


const Layout = ({ className, children }: LayoutProps): React.ReactNode => {
  const [loaded, setLoaded] = React.useState(false)
  React.useEffect(() => {
    setTimeout(() => setLoaded(true), 5)
  }, [])
  
  const data = useStaticQuery(graphql`
    query LayoutQuery {
      sanitySiteSettings {
        title
        description
      }
    }
  `)

  const { 
    sanitySiteSettings: { 
      title, description, shortcutIcon
    }
  } = data
  const meta = [
    { property: "og:type", content: "website" },
    { property: "og:url", content: `${process.env.GATSBY_SITE_BASE_URL}` },
    { charSet: 'UTF-8' },
    { name: "viewport", content: "width=device-width, user-scalable=no, initial-scale=1.0, maximum-scale=1.0, minimum-scale=1.0" },
    { httpEquiv: "X-UA-Compatible", content: "ie=edge" },
    { name: "apple-mobile-web-app-capable", content: "yes" },

    { property: "og:title", content: title },
    { name: "twitter:title", content: title },
    { name: "twitter:card", content: "summary_large_image" },
    { name: "twitter:site", content: "samarskaya" },
    
    { name: "description", content: description },
    { name: "twitter:description", content: description },
    { property: "og:description", content: description },
  ]

  return (
    <>
      <Helmet {...{ meta, title }} >
        <link rel="icon" type="image/png" href="/images/favicon-32x32.png" sizes="32x32" />
        <link rel="icon" type="image/png" href="/images/favicon-16x16.png" sizes="16x16" />
      </Helmet>
      <MouseTrail />
      <Top id="top" />
      <Header />
      <Wrapper className={loaded ? `${className} loaded` : `${className} loading`}>
        {children}
      </Wrapper>
    </>
  )
}


const Wrapper = styled.div`
  position: relative;
  z-index: 100;
  min-height: 110vh;
  width: calc(100vw - 40px);
  margin: auto;
  @media only screen and (min-width: 985px) {
    width: 945px;
  }
  padding-bottom: 100px;
`


const Top = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 1px;
  height: 1px;
`


export default Layout
