import React from "react"
import styled from 'styled-components'
import BlockContent from '@sanity/block-content-to-react'
import { palette } from '@theme' 


const BlockSerializer = ({children, node}: { children: any, node: any }): JSX.Element => {
  if(!children) return <></>
  switch(node.style) {
    case 'h1' :
      return <H1>{children}</H1>
    case 'h2' :
      return <H2>{children}</H2>
    case 'h3' :
      return <H3>{children}</H3>
    default:
      return <Paragraph>{children}</Paragraph>
  }
}

const BlockRenderer = ({ children }: { children: any }) => {
  return (
    <StyledBlockContent
      blocks={children}
      serializers={{
        types: {
          block: (props: any) => (<BlockSerializer {...props} />),
        },
        marks: {
          cyan: (props: any) => (<CyanText {...props} />),
        },
      }}
    />
  )
}



export const CyanText = styled.span`
  color: rgba(66, 181, 246, 1);
`


const StyledBlockContent = styled(props => <BlockContent {...props} />)`
    > p {
      margin-bottom: 1em;
      &:last-of-type {
        margin-bottom: 0;
      }
    }
    ul {
      margin-left: 25px;
    }
    li {
      list-style-type: disc;
    }
`



export const H1 = styled.h1`
  font-family: 'Editorial', serif;
  font-size: 28px;
  letter-spacing: 0;
  line-height: 140%;
`

export const H3 = styled.h3`
  font-size: 15px;
  margin-top: 3em;
  margin-bottom: 1em;
  text-transform: uppercase;
  font-weight: bold;
`

export const H2 = styled.h2`
  font-family: Graphik, sans-serif;
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 100%;
  margin-bottom: 0.8em;
`


export const Paragraph = styled.p`
  font-family: Georgia, serif;
  font-size: 16px;
  line-height: 135%;
  a {
    text-decoration-color: rgba(0, 0, 0, 1);
  }
  @media only screen and (min-width: 744px) {
    font-size: 18px;
  }
`


export default BlockRenderer


/*
wyeth 500 for my name 
graphik medium for small titles 
pp editorial new regular for H1 
immortel infra g1 for text 
*/
