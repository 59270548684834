import React from "react"
import styled from 'styled-components'
import { Link } from 'gatsby'


const Header = () => (
  <Wrapper>
    <Link to="/">Prof. SAMARSKAYA</Link>
  </Wrapper>
)


const Wrapper = styled.header`
  width: calc(100vw - 40px);
  margin: 1em auto 2em auto;
  @media only screen and (min-width: 985px) {
    width: 945px;
  }
  font-family: Wyeth, sans-serif;
  font-size: 32px;
`


export default Header