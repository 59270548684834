import React from 'react'
import styled from 'styled-components'
import { useInView } from 'react-intersection-observer'


// TODO import { imgixPath } from '@utils'
const imgixPath = (input: string) => input
// const imgixPath = (input: string) => input.replace('cdn.sanity.io/images/zchnowat/production', 'franklyn.imgix.net')
// https://cdn.sanity.io/images/y6qitgkc/production/
// twa-2021.imgix.net

const Picture = ({
  objectFit = 'contain',
  className = '',
  src,
  mobileSrc,
  dimensions,
  alt,
  noFadeIn,
  columns,
  children,
  ...props
}: PictureProps): React.ReactElement => {
  if(!src) return <></>
  // Set CDN src. 
  const mobileCdnSrc = (!!mobileSrc) ? imgixPath(mobileSrc) : undefined
  const cdnSrc = imgixPath(src)

  const { ref, inView } = useInView({
    threshold: .15,
    triggerOnce: true
  })
  
  // approximate width-of-layout cut by column width
  const desktopCut = (w: number) => !columns ? w : Math.floor(w * (columns / 12))

  return (
      <picture>
        {!!children && children}
        {!children && (
          <>
            {mobileCdnSrc && (
              <source type="image/webp" media="(max-width: 767px)"  srcSet={`${mobileCdnSrc}?fm=webp&w=840`} />
            )}
            {!mobileSrc && (
              <source type="image/webp" media="(max-width: 767px)"  srcSet={`${cdnSrc}?fm=webp&w=840`} />
            )}
            <source type="image/webp" media="(min-width: 1280px)" srcSet={`${cdnSrc}?fm=webp&w=${desktopCut(1200)}, ${cdnSrc}?fm=webp&w=${desktopCut(2400)} 2x`} />
            <source type="image/webp" media="(min-width: 1024px)" srcSet={`${cdnSrc}?fm=webp&w=${desktopCut(1024)}, ${cdnSrc}?fm=webp&w=${desktopCut(2048)} 2x`} />
            <source type="image/webp" media="(min-width: 768px)"  srcSet={`${cdnSrc}?fm=webp&w=${desktopCut(834)},  ${cdnSrc}?fm=webp&w=${desktopCut(1668)} 2x`} />
          </>
        )}

        <StyledImage
          src={`${cdnSrc}?fm=jpg`}
          alt={alt}
          {...{ objectFit, ref, }}
          {...dimensions}
          visible={inView}
          className={`loadable ${className}`}
          {...props}
          {...{ noFadeIn }}
        />
      </picture>
  )
}



// Styling


interface StyledImageProps {
  visible: boolean
  objectFit: 'contain' | 'cover'
  aspectRatio?: number
  noFadeIn?: boolean;
}

const StyledImage = styled.img.attrs(
  ({ visible, noFadeIn }: StyledImageProps) => {
  return { style: {
    opacity: noFadeIn || visible ? 1 : 0,
  } }}
)<StyledImageProps>`
  display: block;
  width: 100%;
  height: auto;
  margin: 0;
  padding: 0;
  border: 0;
  object-fit: ${props => props.objectFit};
  transition: opacity 0.5s ease-in-out;
  @media only screen and (min-width: 768px) {
    transition: opacity 0.5s ease-in-out, transform 1.5s ease-in-out;
  }
`



// Typing

type PictureProps = {
  src: string
  mobileSrc?: string
  objectFit?: 'contain' | 'cover'
  dimensions?: {
    width: number
    height: number
    aspectRatio: number
  }
  noFadeIn?: boolean
  className?: string
  alt?: string
  columns?: number
  mobileColumns?: number
  children?: React.ReactNode
}



export default Picture
