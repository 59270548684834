import React from 'react'
import styled from 'styled-components'
import { useInView } from 'react-intersection-observer'


const imgixPath = (input: string) => input


const Video = ({ autoPlay, muted, loop, playsInline, src }: VideoProps): React.ReactElement => {
  if(!src) return <></>

  const { ref, inView } = useInView({
    threshold: .15,
    triggerOnce: true
  })
  
  const [visible, setVisible] = React.useState(false)
  React.useEffect(() => {
    if(visible) return
    if(inView) setVisible(true)
  }, [inView])

  return (
    <VideoTag {...{ autoPlay, muted, loop, playsInline, visible, ref }}>
      <source src={imgixPath(src)} />
    </VideoTag>
  )
}



interface VideoTagProps { 
  visible: boolean
}

const VideoTag = styled.video.attrs(
  ({ visible }: VideoTagProps) => {
  return { style: {
    opacity: visible ? 1 : 0,
  } }}
)<VideoTagProps>`
  width: 100%;
  max-width: 100%;
  transition: opacity 0.25s ease-in-out;
`


type VideoProps = {
  autoPlay?: boolean
  muted?: boolean
  loop?: boolean
  playsInline?: boolean
  src: string
}


export default Video