import React from "react"
import styled from 'styled-components'
import { Link } from 'gatsby'


export default () => (
  <Wrapper to="/" >home</Wrapper>
)


const Wrapper = styled(props => <Link {...props} />)`
  display: block;
  width: 30px;
  height: 30px;
  margin-bottom: 2em;
  @media only screen and (min-width: 744px) {
    margin-bottom: 4em;
  }
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url('/images/back.svg');
  overflow: hidden;
  text-indent: -9999px;
`