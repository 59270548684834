import styled from 'styled-components'

const Grid = styled.div`
  @media only screen and (min-width: 744px) {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-column-gap: 40px;
  }
`

export default Grid