import React from "react"
import styled from 'styled-components'


export default () => {
  const scrollToTop = () => window?.scrollTo(0,0)
  return (
    <Wrapper onClick={scrollToTop} />
  )
}


const Wrapper = styled.div`
  width: 30px;
  height: 30px;
  margin-top: 3em;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url('/images/up.svg');
`