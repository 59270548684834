import React from "react";
import useWindowSize from "../utils/useWindowSize";
import { clamp } from "lodash";

/*
  Mouse trail adapted from Reach class component by Noah Yamamoto https://noahyamamoto.com
  … in turn from a jQuery Codepen by Bryan C https://codepen.io/bryjch/pen/QEoXwA
*/

interface Point {
  x: number;
  y: number;
  lifetime: number;
}

class Point {
  constructor(x: number, y: number) {
    this.x = x;
    this.y = y;
    this.lifetime = 0;
  }
}

interface MouseTrailProps {
  duration?: number;
  size?: number;
  color?: string | "rainbow";
  under?: boolean;
}

const defaultMouseTrailProps: MouseTrailProps = {
  duration: 100,
  size: 7,
  color: "#000000",
  under: false,
};

export default (props: MouseTrailProps) => {
  const { duration, size, color, under } = {
    ...defaultMouseTrailProps,
    ...props,
  };
  const canvas = React.useRef<HTMLCanvasElement>(null);
  const { width, height } = useWindowSize();
  React.useEffect(() => {
    if (!canvas.current) return;
    const context = canvas.current?.getContext("2d");
    if (!context) return;
    context.canvas.width = width;
    context.canvas.height = height;
  }, [width, height]);

  React.useEffect(() => {
    const points: Array<Point> = [];
    const addPoint = (x: number, y: number) => points.push(new Point(x, y));

    const mouseMove = (e: { clientX: number; clientY: number }) =>
      addPoint(
        e.clientX - (canvas.current?.offsetLeft || 0),
        e.clientY - (canvas.current?.offsetTop || 0)
      );

    const touchMove = (e: TouchEvent) =>
      addPoint(
        e.touches[0].clientX - (canvas.current?.offsetLeft || 0),
        e.touches[0].clientY - (canvas.current?.offsetTop || 0)
      );

    window.addEventListener("mousemove", mouseMove, false);
    window.addEventListener("touchmove", touchMove, false);

    const context = canvas.current?.getContext("2d");

    const rainbowColor = (decline: number, incline: number) => {
      return `rgb(
        ${Math.floor(255)},
        ${Math.floor(200 - 255 * decline)},
        ${Math.floor(200 - 255 * incline)}
      )`;
    };

    const animatePoints = () => {
      if (!context) return;
      context.clearRect(0, 0, context.canvas.width, context.canvas.height);

      for (let i = 0; i < points.length; ++i) {
        const point = points[i];
        let lastPoint;

        if (points[i - 1] !== undefined) {
          lastPoint = points[i - 1];
        } else lastPoint = point;

        point.lifetime += 1;

        var incline = point.lifetime / duration!;
        var decline = 1 - incline;

        if (point.lifetime > duration!) {
          // If the point dies, remove it.
          points.shift();
        } else {
          // Otherwise animate it:

          // As the lifetime goes on, lifePercent goes from 0 to 1.
          const lifePercent = point.lifetime / duration!;
          const spreadRate = clamp(size! * (1 - lifePercent), 1, size!);

          context.lineJoin = "round";
          context.lineWidth = spreadRate;

          context.strokeStyle =
            color === "rainbow" ? rainbowColor(decline, incline) : color!;
          context.beginPath();

          context.moveTo(lastPoint.x, lastPoint.y);
          context.lineTo(point.x, point.y);

          context.stroke();
          context.closePath();
        }
      }
      requestAnimationFrame(animatePoints);
    };

    animatePoints();

    return () => {
      window.addEventListener("mousemove", mouseMove);
      window.addEventListener("touchmove", touchMove);
    };
  }, []);

  return (
    <canvas
      ref={canvas}
      {...{ width, height }}
      style={{
        position: "fixed",
        zIndex: under ? -1 : 999999,
        top: "0px",
        left: "0px",
        width: "100%",
        height: "100vh",
        pointerEvents: "none",
      }}
    />
  );
};
